.avatar_profile {
  border-radius: 30px;
}

.container_profile > .ant-row {
  display: flex;
  align-items: center;
  margin-top: 20px !important;
}

.text-email {
  word-break: break-all;
}
